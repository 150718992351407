.upi-column-outer {
  display: flex;
  width: 60%;
  overflow: scroll;
  /* scrollbar-color: red orange; */
  /* scrollbar-width:thin; */
}

.upi-column {
  background-image: url(./assets/images/bg-upi.png);
  border: none;
  height: 60px;
  display: flex;
  width: 80px !important;
  flex: 1;
  min-width: 80px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  margin-right: 5px;
}
.casinoTabs .nav-tabs {
  display: none !important;
}
.list-icon {
  width: 12px;
  margin: 0 2px;
}
.active-side {
  border: solid 1px #ffb80c !important;
  background: #ffb80c !important;
  color: white !important;
  padding: 4px 9px;
  border-radius: 5px;
}
.upi-column:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(
    108.08deg,
    hsla(0, 0%, 69.8%, 0.4) 50%,
    hsla(0, 0%, 100%, 0.4)
  );
  z-index: 1;
}

.offer-tab {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px;
}

.offer-tab p {
  margin: 0;
  padding: 0;
  font-size: 12px;
}
.offer-tab-active {
  border: 2px solid black;
}

.usrTrans-seperate {
  background: white;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

.span-deposit {
  font-weight: 700 !important;
  font-family: monospace !important;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #40424f;
}

.inner-deposit {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 60%;
}
.inner-deposit div {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.inner-deposit div img {
  width: 50%;
}
.inner-deposit div span {
  color: blue;
  text-decoration: underline;
  font-size: 11px;
  font-weight: 600;
}

.inner-sidebar-content {
  background: transparent;
  min-height: calc(100vh - 151px);
  padding: 0;
}

.balance-sec {
  background: #262c32;
  color: #fff;
  padding: 14px;
  border-radius: 14px;
  max-width: 282px;
}
.balance-sec span {
  display: inline-block;
  background: #ffb80c;
  color: #000;
  padding: 4px 5px;
  font-size: 14px;
  border-radius: 6px;
  font-weight: 700;
}

.message-chat {
  background-image: url("https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png");
  background-size: 50%;
}
.message-chat-inner {
  height: 100%;
  min-height: 81vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding-bottom: 60px;
}

.message-box {
  background: #dcf8c6;
  padding: 5px;
  max-width: 60%;
  width: fit-content;
  margin-left: auto;
  display: flex;
  border-radius: 10px;
  word-break: break-word;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}
.message-wrapper {
  background: #ffffff;
  padding: 5px;
  max-width: 60%;
  width: fit-content;
  display: flex;
  border-radius: 10px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  word-break: break-word;
}

.balance-label-detail {
  background: rgb(48, 48, 48);
  color: #fff;
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
  text-align: center;
  height: 2.4285714285714284rem;
  margin-top: -1px;
}
.balance-label-detail span {
  font-size: 1rem;
  line-height: 22px;
}
button.theme-btn {
  background: #ffb80c;
  border-color: #ffb80c;
  color: #000;
  padding: 9px 32px;
  font-size: 20px;
  font-weight: 600;
  transition: 0.5s;
}
.balance-label {
  /* background: rgb(48, 48, 48); */
  background-color: transparent;
  color: black;
  padding: 8px 0px;
  border-radius: 8px;
  /* text-align: center; */
  font-size: 1.2rem;
  font-weight: 600;
  /* height: 3rem; */
}
.balance-label-inner span {
  font-size: 0.7142857142857143rem;
}

.profit-loss-footer {
  background: rgb(226 234 239/1);
}
.profit-loss-footer-inner {
  padding: 5px;
  border-bottom: 1px solid rgb(156 177 189/1);
}
.profit-loss-footer-inner > div {
  font-size: 0.8571428571428571rem !important;
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profit-loss-footer-inner-footer {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  font-size: 0.8571428571428571rem !important;
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pl-filter {
  background-color: rgb(48, 48, 48);
}

.pl-filter-first-time {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid #ffb80c;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
.pl-filter-first button {
  margin-bottom: 10px;
  width: 100%;
}
.pl-filter-first-time svg {
  color: #ffb80c;
  font-size: 20px;
}

.calendar-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.refresh-icon {
  animation: spin 1s linear infinite;
  transform: rotate(0);
}
@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
  50% {
    transform: rotate(180deg);
  }
}

.select-container {
  background: #262c32;
  padding: 8px;
  text-align: center;
}

.select-container select {
  width: 100%;
  padding: 7px 10px;
  background: transparent;
  border-color: #ffb80c;
  color: #ffb80c;
  font-size: 19px;
  font-weight: 500;
  border-radius: 5px;
}
select option {
  background: #262c32;
  padding: 20px;
}

.current-bets-table .master-pass {
  padding-left: 0;
  margin-left: 0px;
}
.master-pass {
  font-size: 0.9285714285714286rem;
}
.select-container select:focus {
  outline: none;
}
.all-bets {
  margin-bottom: 32px;
}

.single-match-body .bet-name.lay {
  background: #fcc6d1;
  margin-right: 8px;
  font-size: 0.9285714285714286rem;
}
.single-match-body .bet-name.back {
  margin-right: 8px;
}
.single-match-body.current-bets-table .lay-back td {
  background: #eef6fb;
  font-weight: 700;
  padding: 0.5rem;
  font-size: 0.9285714285714286rem;
}

.single-match-body.current-bets-table td .d-flex {
  color: #6f8898;
  padding: 0;
}
.single-match-body.current-bets-table td {
  line-height: inherit;
}

.current-bets-table .odds-table th {
  background: #d4e0e5;
  font-weight: normal;
  padding-left: 10px;
  color: #000;
  font-size: 0.9285714285714286rem;
}

/* .current-bets-table th .master-pass:first-child {
  font-weight: 600;
} */

.current-bets-table .lay-back td {
  padding-left: 25px;
  padding: 14px 19px;
  font-weight: 700;
}

.match-point .master-pass:first-child::before {
  display: none;
}
.master-pass.first::before {
  content: "";
  border: none;
}

.master-pass.first {
  padding-left: 0px;
}
.single-match-body-mybets-modal .master-pass.first {
  font-size: 0.9285714285714286rem;
  color: #000;
  font-weight: 700;
}
.single-match-body-mybets-modal .master-pass {
  font-weight: 400;
}
.single-match-body-mybets-modal .master-pass::before {
  border-color: transparent transparent transparent #d4e0e5;
}
.master-pass {
  position: relative;

  font-weight: 600;
}
.all-bets {
  margin-bottom: 32px;
}
/* .master-pass::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent #9cb1bd;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
} */

.arrow-icon {
  margin: 0 0.2857142857rem;
  font-size: 13px;
  color: #5a5e62;
}

.profit-loss-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: rgb(156 177 189/1);
  font-size: 13px;
}
.profit-loss-div-inner {
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem;
  background-color: #e2eaef;
  align-items: center;
  font-size: 0.9285714285714286rem;
  font-weight: 700;
  border-bottom: 2px solid rgb(156 177 189/1);
}
.profit-loss-div-inner-tabel tbody td {
  font-size: 0.7142857142857143rem;
}
.profit-loss-div-inner-tabel tbody tr td {
  background-color: #e2eaef !important;
  border-right: 1px solid rgb(156 177 189/1);
}
.profit-loss-div-inner-tabel tbody tr td:last-child {
  border-right: none !important;
}
.profit-loss-div-inner-tabel tbody tr {
  border-top: 1px solid rgb(156 177 189/1);
}

.bets-table tbody tr td {
  background-color: rgb(226 234 239/1);
  border-right: 1px solid rgb(156 177 189/1);
}
.bets-table tbody tr {
  border-bottom: 1px solid rgb(156 177 189/1);
}
.bets-table tbody tr:last-child {
  border-width: 0;
}

.bets-table tbody tr td:last-child {
  border-right: none !important;
}

.single-match-body.current-bets-table .lay-back td {
  background: #eef6fb;
  font-weight: 700;
  padding: 0.5rem;
  font-size: 0.9285714285714286rem;
}

.single-match-body.current-bets-table td .d-flex {
  color: #6f8898;
  padding: 0;
}
.single-match-body.current-bets-table td {
  line-height: inherit;
}

.profit-loss-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: rgb(156 177 189/1);
  font-size: 13px;
}
.profit-loss-div-inner {
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem;
  background-color: #e2eaef;
  align-items: center;
  font-size: 0.9285714285714286rem;
  font-weight: 700;
  border-bottom: 2px solid rgb(156 177 189/1);
}
.profit-loss-div-inner-tabel tbody td {
  font-size: 0.7142857142857143rem;
}
.profit-loss-div-inner-tabel tbody tr td {
  background-color: #e2eaef !important;
  border-right: 1px solid rgb(156 177 189/1);
}
.profit-loss-div-inner-tabel tbody tr td:last-child {
  border-right: none !important;
}
.profit-loss-div-inner-tabel tbody tr {
  border-top: 1px solid rgb(156 177 189/1);
}
.single-match-body-parlaymybets .show-hide-bet svg {
  font-size: 18px;
  transition: 0.4s;
  margin-top: -15px;
}

.single-match-body-parlaymybets .show-hide-bet {
  background: #d4e0e5 !important;
  border: none;
  padding: 2px 47px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0px 0px 14px 14px;
  height: 18px;
  margin-top: -2px;
}
.bets-table thead {
  background: #9cb1bd;
}

.bets-table thead th {
  padding: 5px 11px;
}
.current-bets-table td {
  width: 33.33%;
}
.current-bets-table {
  /* border-bottom: 8px solid #9cb1bd; */
  border-bottom: 8px solid #d4e0e5;
  /* border: 1px solid #d4e0e5; */
  border-radius: 14px;
  overflow: hidden;
}
.show-hide-bet {
  background: #d4e0e5;
  border: none;
  padding: 2px 47px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0px 0px 14px 14px;
}

.show-hide-bet.arrow-up {
  background: rgb(156 177 189/1);
}
.show-hide-bet svg {
  font-size: 21px;
  transition: 0.4s;
}
.show-hide-bet.arrow-up svg {
  transform: rotate(180deg);
}
.all-bets {
  margin-bottom: 32px;
}
.market-depth-body .to-back-to-lay table td {
  text-align: center !important;
}

.current-bets-table {
  /* border-bottom: 8px solid #9cb1bd; */
  border-bottom: 8px solid #d4e0e5;
  /* border-left: 1px solid #d4e0e5;
  border-right: 1px solid #d4e0e5; */
  /* border: 1px solid #d4e0e5; */
  border-radius: 14px;
  overflow: hidden;
}
.current-bets-table.show {
  border-color: #9cb1bd;
}

.sport-header-inner-new {
  overflow-x: scroll;
  padding: 5px;
  padding-bottom: 0;
  background-color: rgb(48, 48, 48);
  flex-wrap: nowrap;
  display: flex;
  overflow-y: hidden;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  scrollbar-width: none;
  flex-direction: column;

  color: white;
  border-bottom: 1px solid white;
  margin-bottom: 10px;
}
.sport-header-inner-new div {
  /* margin-right: 20px; */
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
}
.sport-header-inner-new div h3 {
  font-size: 0.9285714285714286rem;
  font-weight: 700;
}

.sport-header-inner-new div h3.active::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 20px;
  right: 0;
  margin: 0 auto;
  border-radius: 0.15rem;
  width: 100%;
  background: white;
  height: 0.29rem;
}

.no-event {
  line-height: 1.25;
  font-size: 1rem;
  text-align: center;
  padding-bottom: 1.25rem;
  padding-top: 1.5rem;
  background-color: rgb(48, 48, 48);
  border-radius: 0.75rem;
  display: flex;
  width: 120px;
  flex-direction: column;
  align-items: center;
  color: white;
}
.no-event-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 380px;
  width: auto;
  background: white;
  margin-top: 10px;
}

.market-depth-modal {
  position: fixed;
  bottom: 0;
  background: #fff;
  z-index: 99999999;
  width: 50%;
  /* top: 0; */
  left: 22%;
  right: 0;
  /* box-shadow: 10px 10px 10px 10px; */
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
  padding: 0;
  transition: all 0.3s ease;
  will-change: top;
  height: calc(100vh - 5.85714rem);
  border: 0.25rem solid rgb(48, 48, 48);
}
.market-depth-modal-casino {
  position: fixed;
  bottom: 0;
  background: #000;
  z-index: 99999999;
  width: 100%;
  top: 120%;
  left: 0;
  right: 0;
  box-shadow: 10px 10px 10px 10px;
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
  padding: 0 0.25rem;
  transition: all 0.3s ease;
  will-change: top;
}
.market-depth-modal-footer {
  position: absolute !important;
  top: 0;
}
.active-model {
  top: 0 !important;
}
.market-depth-modal-announcement {
  border-radius: 0% !important;
  background-color: #fff;
}
.market-depth-body {
  overflow-y: auto !important;
  background: #fff;
  height: 100%;
  border-radius: 12px 12px 0 0;
}

.market-title {
  color: #fff;
  background: rgb(48, 48, 48);
  text-align: center;
  padding: 13px 6px;
  /* font-size: 5px; */
}
.market-title-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.market-depth-body .match-point {
  background: #d4e0e5;
  padding: 10px 14px;
  text-align: center;
  border-radius: 12px 12px 0 0;
  height: 2.857142857142857rem;
}
.market-depth-body table td {
  border: 1px solid #d4e0e5;
  padding: 0.5rem;
  line-height: 18px;
  font-size: 0.7142857142857143rem;
  text-align: left !important;
  color: #1e1e1e;
}
.market-depth-body table td strong {
  font-size: 15.38px;
}

.calendar-market {
  /* min-height: 400px !important; */
  /* top: 57%; */
  height: auto !important;
}
.calendar-market-during {
  /* top: 89% !important; */
  min-height: 90px !important;
}
.calendar-market .market-title h4 {
  color: white !important;
}

.calendar-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.calendar-button button {
  border: none;
  font-weight: 700;
  background-color: #ffb80c;
  color: white;
}

.select-container.bet-history-dropdown .dropdown-item {
  padding-left: 103px;
}
.bet-history-dropdown .dropdown-toggle:active,
.bet-history-dropdown .dropdown-toggle:hover,
.bet-history-dropdown .btn.show:active {
  background: transparent !important;
}

.pl-filter-first {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pl-filter-first-time svg {
  color: #ffb80c;
  font-size: 20px;
}
.pl-filter-first button {
  border: none;
  font-weight: 700;
  background-color: #ffb80c;
  color: white;
}

header .subNav ul li .active {
  background: rgb(48, 48, 48) !important;
  color: white !important;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid #3db39e54;
  border-right: 6px solid #3db39e54;
  border-bottom: 6px solid #3db39e54;
  border-top: 6px solid #3db39e;
  border-radius: 100%;
  display: none;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.loader-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
}
.loader-inner-icon {
  background-color: #1b1f23;
  border-radius: 9999px;
  animation: loadBar 0.8s ease infinite;
  width: 0.2857142857142857rem;
  height: 0.2857142857142857rem;
  margin-right: 0.5rem;
}
@keyframes loadBar {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
    transform: scale(1.4);
  }
  100% {
    opacity: 0.1;
  }
}

.loading {
  background-color: #262c32b3;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
}
.loading-box {
  background: rgba(27, 31, 35, 0.7);
  border: 1px solid #8b6b05;
  width: 7rem;
  border-radius: 0.5rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-spinner {
  height: 1.4285714286rem;
  position: relative;
  width: 50%;
}
.loading-spinner::before,
.loading-spinner::after {
  background-color: #ffb80c;
  content: "";
  height: 1.4285714286rem;
  position: absolute;
  width: 1.4285714286rem;
}

.loading-spinner::before {
  animation: spinnerLeftRight 0.6s 95ms infinite;
  border-radius: 50%;
  left: -10%;
  opacity: 1;
}
.loading-spinner::after {
  animation: spinnerLeftRight 0.6s infinite;
  border-radius: 100%;
  left: -15%;
  opacity: 0.4;
}
@keyframes spinnerLeftRight {
  0% {
    left: 75%;
  }

  50% {
    left: -15%;
  }

  to {
    left: 75%;
  }
}

.subcasino span {
  padding: 4px 6px;
  margin-right: 7px;
  font-size: 12px;
}
.subcasino span {
  display: block;

  border-radius: 4px;

  font-weight: 700;

  text-transform: uppercase;
  cursor: pointer;
}

.subcasino {
  background: #333333;
  color: #fff;
  padding: 8px 6px;
  /* border-radius: 4px; */
  /* margin-bottom: 10px; */
}
.subcasino span.active {
  background: #ffc800;
  color: #000;
}
.form-floating > label {
  z-index: 1 !important;
}

.selection-slip {
  display: flex;
  align-items: center;
  flex-direction: row;

  /* justify-content: center; */
}

.filter-new {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #e6e6e6;
  border-bottom: 1px solid #7E97A7;
  padding: 12px;
  margin-bottom: 10px;
}
.filter-new-inner {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right:10px;
  width: 13%;
}
.filter-new-inner label {
  width: 60%;
  margin-bottom: 0;font-size: 13px;
}
.filter-new-inner select {
  border-radius: 0 !important;
  width: 100%;
  height: 28px;
  padding: 2px 7px;
  font-size: 13px;
}
.filter-new-inner input{
  border-radius: 0 !important;
  width: 100%;
  height: 28px;
  padding: 2px 7px;
  font-size: 13px;
}


.get-history{
  border: none;
  border-radius: 4px;
  background: black;
  color: #ffb80c!important;
  font-size: 12px;
  padding: 5px 25px;
  font-weight: 600;
}

.fitler-new-button{
  display: flex;
  align-items: center;
}
.fitler-new-button button{
  border-radius: 3px;
  border: 1px solid #c5c5c5;
font-size: 13px;
margin-right: 6px;
padding:4px 10px;

}

.table thead th{
  background: black;
  color: #ffb80c;
  font-weight: 600;
  font-size: 13px;
}
.angle_unicode{
  margin: 0 4px;
}
.myprofile{
  font-size: 13px;
background: white;
}
.myprofile-list{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  
}
.myprofile-list div {
  width: 100%;
  padding: 6px 10px;
  color: #151515;
  border-bottom: 1px solid #F2DCA7;
}
.myprofile-list .active {
  background-color: #F2DCA7;
  color: #1b2d39;
  font-weight: 600;
}
.red{
  color: red;
}
.expand-open{
  color: green;
  text-decoration: none;
}


.sum-pl dt {
  width: 82%;
  padding: 0 10px 5px;
  color: #243a48;
  float: left;
  text-align: right;
  /* font-weight: normal; */
}

.sum-pl dd {
  margin: 0;
  padding: 0 10px 5px;
  text-align: right;
}

.filter-new-inner select option{
  background: white;
  color: black;
}

.cBlock{
  position: relative;
}
.game-item-overlay {
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  background-color: rgba(117,117,117,.5);
}
.cBlock:hover .game-item-overlay{
display: flex;
}