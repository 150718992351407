
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body{
   
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    background: #f2f2f2;
    width: 100%;
}

.container-fluid{
    --bs-gutter-x: 0;
}

header{
    background: #030A1F;
    box-shadow: 5px 0px 5px 0px rgba(0,107,179,0.25);
  
    .navbar-brand{
        width: 20%;
    }

    .nav-link{
      
      
            margin-left: 3px;
            background: #ffb80c;
            color: black;
            margin-right: 5px;
            border-radius: 20px;
            padding-right: 12px !important;
            line-height: 12px;
            padding: 6px 12px 6px 8px !important;
        
        img{
            width: 20px;
            margin-right: 6px;
        }
        // &:hover{
        //     color: #ffb80c;
        //     opacity: .7;
        // }
        // &:focus, &.active{
        //     color: #ffb80c!important;
        //     opacity: 1;
        // }

        // &.active:hover{
        //     color: #ffb80c!important;
        //     opacity: .7;
        // }

    }

    .amounts{
        background: #303030;
        padding: 3px 8px;
        border-radius: 30px;
        font-weight: 600;
        line-height: 20px;
        color: white;
        img{
            width: 20px;
        }
    }

    .uDetails{
          vertical-align: middle;
          margin-left: 10px;
        .u-name{
            display: inline-block;
            font-size: 12px;
            line-height: 12px;
            font-weight: 500;
            vertical-align: middle;
        }
        .u-img{
            width: 30px;
            margin-left: 8px;
            display: inline-block;
            vertical-align: middle;
            img{
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
        }

        .btn{
            padding:4px 0px 0px;
            background: transparent;
            border: 0px;
            color: white;
            &:active{
                background: transparent;
                color: black;
            }
        }

        .dropdown-menu{
            min-width: 300px;
            border: 0px;
            background: rgb(48, 48, 48);
            box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.05);
            max-height: 85vh;
            overflow-y: scroll;
            .whiteBox{
                background: #8a92ab26;
            }
            .uimage{
                width: 35px;
                height: 35px;
                margin-left: 5px;
                border-radius: 50%;
                display: inline-block;
                border: solid 1px white;
                box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.6);
            }
            .uname{
                font-weight: 400;
                font-size: 15px;
                display: inline-block;
                margin-left: 10px;
                color: white;
            }


            .values{
                background: #8a92ab26;
                padding: 10px;
                font-size: 16px;
                font-weight: 500;
                color: white;
                .c-red{
                    color: #FF2424;
                }
                span{
                    font-size: 13px;
                    font-weight: 400;
                    display: block;
                    color: white;
                }
               
                .br-b{
                    border: solid 1px rgba($color: #EAEAEA, $alpha: .3);
                    border-width: 0px 1px 1px 0px;
                }

                .b-b{
                    border: solid 1px  rgba($color: #EAEAEA, $alpha: .3);
                    border-width: 0px 0px 1px 0px;
                }

                .b-r{
                    border: solid 1px  rgba($color: #EAEAEA, $alpha: .3);
                    border-width: 0px 1px 0px 0px;
                }
            }


            .u-menus{
                list-style: none;
                margin: 0px 10px;
                padding: 0px;
                li{
                    padding: 8px 0;
                    border-bottom: solid 1px  rgba($color: #EAEAEA, $alpha: .12);
                    a{
                        color: white;
                        font-size: 13px;
                        text-decoration: none;
                        img{
                            width: 20px;
                            display: inline-block;
                            margin-right: 5px;
                            vertical-align: middle;
                        }
                    }
                    &:last-child{
                        border-bottom: 0px;
                    }
                }
            }


            .btn-red{
                background: #F15B25;
                font-size: 13px;
                border-color: #F15B25;
                display: block;
                color: white;
                text-transform: none;
                padding: 8px;
                margin-top: 15px;
            }
        }
    }


    .subNav{
       
            background-image: linear-gradient(133deg, #ffb80c, #ffd97b);
        
        ul{
            margin: 0px;
            padding: 0px;
            list-style: none;
            li{
                display: inline-block;
                position: relative;
                a{
                  
                        padding:10px;
                        display: block;
                        font-weight: 500;
                        font-size: 12px;
                        color: black;
                        // text-transform: uppercase;
                        text-decoration: none;
                        font-weight: 600;
                    
                }
                // &::after{
                  
                //         content: "";
                //         width: 1px;
                //         height: 100%;
                //         background: #1E294D;
                //         position: absolute;
                //         right: 0px;
                //         top: 0;
                    
                // }
                &:last-child{
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
}

.login{
    /*background: url(./img/login_bg.png) no-repeat left bottom ;
    background-size: cover;*/
    height: 100vh;
    background: #303030;
    .loginForm{
        background: white;
        padding: 25px;
        border-radius: 5px;
        .form-label{
            font-weight: 600;
            letter-spacing: 1px;
            color: black;
            margin-bottom: 3px;
           

        }

        .form-control{
            box-shadow: 0px 0px 5px 0px rgba(0,107,179,0.25);
            border-radius: 3px;
            border: 0px;
        }
        .f-password{
            font-size: 12px;
            color: white;
        }
        .btn{
            
                width: 100%;
                /* background: #5AC8F4; */
                /* border-color: #5AC8F4; */
                background-image: linear-gradient(rgb(255, 230, 157), rgb(175, 153, 85));
                border: none;
                color: black;
                font-weight: 600;
            
        }
    }
}


.left-menu{
//   margin-right: 10px;
    // min-height: calc(100vh - 60px);
    width: 15%;
    // padding: 0 10px;
    h2{
        font-size: 16px;
        background: #202A39;
        padding: 15px 15px;
        color: #ffffff;
        border-radius: 3px 3px 0px 0px;
        margin: 0px;
    }

    .blocks{
        background: #303030;
        border-radius: 0px 0px 3px 3px;
        padding: 0px 15px;
        margin: 0px;
        list-style: none;
        li{
            padding: 12px 0px;
            border-bottom: solid 1px rgba(255,240,218,.1);
            a{
                color: white;
                text-decoration: none;
                &:hover{
                    color: #ffb80c;
                }
            }
            &:last-child{
                border-bottom: 0px;
            }
        }
    }

    .LeftTabs{
        // margin-top: 8px;
        .nav-tabs{
            background: #202A39;
            margin: 0px;
            .nav-link{
                border-radius: 0px;
                border: 0px;
                color: #fff;
                font-weight: 600;
                margin: 0px;
                padding: 8px 10px;
            }
        }

        .tab-content{
            background: #141C33;
            border-radius: 0px 0px 3px 3px;
            padding:0px; 
            .accordion{
                border: 0px;
                .accordion-item{
                    border-radius: 0px;
                    border: 0px;
                    border-bottom:solid 1px rgba(3, 10, 31);
                h2{
                    padding: 0px;
                    border-radius: 0px;
                    .accordion-button{
                       
                         
                          
                                padding: 6px 10px;
                                border-radius: 0px;
                                // background-color: #ffb80c !important;
                                color: black;
                                font-size: 14px;
                                box-shadow: none;
                                font-weight: 600;
                                background-image: linear-gradient(133deg, #ffb80c, #ffd97b);
                           
                        
                        &::after{
                            // background-color: #ffb80c;
                            background-size: 14px;
                            background-position: center;
                            border-radius: 3px;
                            font-weight: 600;
                        }
                    }
                }

                .accordion-body{
                    background: white;
                    color: black;
                    padding: 0px;
                    ul{
                       
                        // padding: 0px 15px;
                        margin: 0px;
                        list-style: none;
                        padding-left: 0;
                        li{
                            padding: 7px 9px;
                            border-bottom: 1px solid rgb(221, 221, 221);
                            a{
                                font-size: 12px;
                                // font-weight: 600;
                                color: black;
                                text-decoration: none;
                                &:hover{
                                    color: #ffb80c;
                                }
                            }
                            &:last-child{
                                border-bottom: 0px;
                            }
                        }
                    }
                }
            }
            }
        }
    }
}

.right-con{
    width: 85%;
    // width: calc(100% - 280px);
}

.whiteBox{
    background: white;
}

.fs-12{
    font-size: 12px;
}

h2{
    font-size: 15px;
    font-weight: 400;
    color: #1FB8F1;
    img{
        width: 25px;
    }

    span{
        background: #F1F1FF;
        padding: 3px 6px;
        font-size: 13px;
        color: #3E4095;
        border-radius: 5px;
        img{
            width: 22px;
            margin-right: 5px;
        }
    }
}

.br-6{
    border-radius: 6px;
}



.matchBox{
    background: rgb(48, 48, 48);
    padding: 10px;
    height: 100%;
    h4{
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        color: white;
        background: url(./img/bat.png) no-repeat left top;
        background-size: 15px;
        
        span{
            display: block;
            font-size: 12px;
            font-weight: 500;
            color: #FB8500;
            padding-top: 6px;
        }
    }
    p{
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 5px;
        color: white;
    }

    h5{
        position: relative;
        font-size: 10px;
        color: #F15B25;
        text-align: center;
        &::before{
            position: absolute;
            height: 1px;
            width: 32%;
            background:#F15B25;
            content: '';
            left: 0px;
            top: 5px;
        }

        &::after{
            position: absolute;
            height: 1px;
            width: 32%;
            background:#F15B25;
            content: '';
            right: 0px;
            top: 5px;
        }
    }

    .oddNumber{
        background: rgba(255,240,218,.3);
        padding: 5px 8px;
        border-radius: 3px;
        color: #fff;
        font-size: 11px;
    }
}



.leagueBox{
  
    .title{
        background:#303030;
        padding: 8px 8px; 
        color: white;
        // border-radius: 4px;
        position: relative;
        img{
            margin-right: 5px;
        }
        .live{
            background: #68D000;
            padding: 2px 5px;
            font-size: 10px;
            color: white;
            border-radius: 3px;
            top: -8px;
            position: absolute;
            line-height: 11px;
        }

        .upcomings{
            background: #FB8500;
            padding: 2px 5px;
            font-size: 10px;
            color: white;
            border-radius: 3px;
            top: -20px;
            position: absolute;
            line-height: 11px;
        }

        .withtime{
            display: inline-block;
            vertical-align: middle;
            background: transparent;
            font-size: 1rem;
            span{
                font-size: 11px;
                display: block;
                background: transparent;
                padding: 0;
                color: #FB8500;
                font-weight: 500;
            }
        }

        .accordion-button{
            background-color: transparent;
            padding: 0px;
            border: 0px;
            .row{
                width: 100%;
                color: #3E4095;
                .text-dark .row{
                    color: black;
                }
                .live{
                    top: -20px;
                }
            }
            &:not(.collasped){
                box-shadow: none;
            }
            &::after{
                background-size: 15px;
                background-position: center;
                background-color: #ACE9FD;
                border-radius: 3px;
            }
        }
    }

.Cashout{
    background: #FFC264;
    display: inline-block;
    padding: 2px 5px;
    font-size: 12px;
    color: #000000;
    font-weight: 500;
    border-radius: 6px;
    img{
        display: inline-block;
        vertical-align: middle;
        margin-right: 0px;
        position: relative;
        top: -1px;
    }
}

.boost{
    background: transparent;
    display: inline-block;
    padding: 2px 5px;
    font-size: 12px;
    color: #FFC264;
    font-weight: 500;
    border-radius: 6px;
    img{
        display: inline-block;
        vertical-align: middle;
        margin-right: 0px;
        position: relative;
        top: -1px;
    }
}

    .accordion-item{
        border: 0px;
        background-color: transparent;
    }
    .accordion-body{
        padding: 0;
    }

    .laegueRow{
        // padding: 5px 0px; 
        color: white;
        background: white;
        border-bottom: solid 1px rgba(255,255,255,.1);
        .text-blue{
            color:#ffb80c;
            font-weight: 500;
        } 
        .bluBoxs{
            background: #89c6f1;
            // width: calc(50% - 4px);
            width: calc(50%);
            // margin: 0px 2px;
            font-weight: 500;
            padding: 5px 0px;
            font-size: 13px;
            // border-radius: 4px;
            
            height: 100%;
            span{
                display: block;
                font-size: 10px;
            }
        }

        .redValue{
            background: #FFDADA;
            color: #D80000;
            font-weight: 500;
            padding: 3px;
            font-size: 10px;
            min-width: 25px;
            margin: 0px 5px;
            line-height: 11px;
            border-radius: 3px;
        }

        .greenValue{
            background: #E7FFD8;
            color: #5D9F34;
            font-weight: 500;
            padding: 3px;
            margin: 0px 5px;
            font-size: 10px;
            min-width: 25px;
            line-height: 11px;
            border-radius: 3px;
        }

        .redBoxs{
            background: #faa9ba;
            // width: calc(50% - 4px);
            width: calc(50%);
            // margin: 0px 2px;
            font-weight: 500;
            padding: 5px 0px;
            font-size: 13px;
            // border-radius: 4px;
            height: 100%;
            span{
                display: block;
                font-size: 10px;
            }
        }

        .whiteBoxes{
            background: #202A39;
            // width: calc(50% - 4px);
            width: calc(50%);
            // margin: 0px 2px;
            font-weight: 500;
            padding: 5px 0px;
            font-size: 13px;
            // border-radius: 4px;
            height: 100%;
            color: white;
            line-height: 34px;
            span{
                display: block;
                font-size: 10px;
            }
        }
        &.active{
            background: #141C33;
            border: solid 1px #F69672;
            border-radius: 5px;
            color: white;
        }

       
    }

    .PremiumBox{
        background: #B9EDFF;
        padding: 5px 15px;
        position: relative;
        .upcomings{
            background: #FB8500;
            padding: 2px 5px;
            font-size: 10px;
            color: white;
            border-radius: 3px;
            top: -10px;
            position: absolute;
            line-height: 11px;
        }
    }
}


.m-banner{
   
    img{
        width: 100%;
    }
}

.text-blue{
    color: #7FD7F7;
}
.f-500{
    font-weight: 500;
}
.nav-tabs{
    border: 0px;
    .nav-link{
        // border: solid 1px #ffb80c;
        // padding: 4px 10px;
        // border-radius: 20px;
        // margin-right: 5px;
        // color: white;
   
            /* border: solid 1px #ffb80c; */
            // padding: 4px 10px;
            font-weight: 600;
            border-radius: 0 !important;
            /* margin-right: 5px; */
            color: white;
      
        &.active{
            
                // border: solid 1px #ffb80c;
                background: rgb(48, 48, 48);
                color: white !important;
                // background-image: linear-gradient(133deg, #ffb80c, #ffd97b);
           
        }
    }
}

.input-fix{
    position: relative;
    padding-top: 10px;
    .form-label{
        position: absolute;
        top: -2px;
        padding: 3px 5px;
        background:white;
        font-size: 12px;
        color: black;
        left: 5px;
    }
    .form-control{
        background-color: transparent;
        color:black;
        font-weight: 600;
        font-size: 14px;

    }
}

.infoTxt{
    font-size: 12px;
    margin: 5px 0 0;
    color: black;
}

.stake-value{
   
        display: inline-block;
        color: white;
        border-radius: 3px;
        font-size: 14px;
        padding: 2px 6px;
        font-weight: 600;
        background: #303030;
        margin-bottom: 5px;
    
    &.active{
        color: #ffffff;
        background: #ffb80c;
        border-color:#ffb80c;
    }

}

.switch {
    background: #141C33;
    border-radius: 4px;
    padding: 5px;
   margin-bottom: 10px;
    .form-check-label{
        font-size: 10px;
    }

    .form-check-input{
        float: right;
    }
}
.lgrey{
    border-color: #F1F1FF;
    opacity: 1;
    margin: 10px 0;
}

.btn-red{
    background: #F15B25;
    font-size: 13px;
    border-color: #F15B25;
    text-transform: uppercase;
}

.btn-green{
    background: #39B200;
    font-size: 13px;
    border-color: #39B200;
    text-transform: uppercase;
    span{
        font-size: 10px;
        text-transform: none;
    }
}



.rightAccordian{
    .accordion-item{
        border: 0px;
        border-radius: 0;
        background: #101933;
       
        &:last-child{
            border-bottom: 0px;
        }
    .accordion-header{
        .accordion-button{
            font-size: 13px;
            padding: 7px 10px;
            border-radius: 0;
            border: 0px;
            color: white;
            background-color: transparent;
            box-shadow: none;
            &::after{
                background-size: 15px;
                background-position: center;
            }
            &:not(.collapsed) {
                background-color:transparent;
            }
        }
    }

    .accordion-body{
        padding: 0px;
       .greyBG{
        background: #141C33;
        color: white;
       }

       .blueBG{
        background: #DEF5FE;
        padding: 7px;
        border-radius: 0 0 4px 4px;
        font-weight: 500;
        span{
            display: block;
            font-size: 11px;
        }
       }
        .blue-check{
            padding: 7px;
            padding-left: 2.3em;
            .form-check-input{
                border-color: #2DBCF1;
            }
            font-size: 13px;
            color:#2DBCF1;
        }

        .f-12{
            font-size: 12px;
            color: #fff;
        }
    }
}
}


.fs-13{
    font-size: 13px;
}

.lh-20{
    line-height: 20px;
}

.lh-35{
    line-height: 35px;
}

.lh-43{
    line-height: 43px;
}


.op-20{
    opacity: .4;
}


.BlackBox{
    background: rgb(48, 48, 48);
    border-radius: 5px;
    .heads{
        padding:8px 10px;
        border-bottom: solid 1px #1C1C1C;
        font-size: 14px;
        color: white;
        .Date{
            text-align: center;
            font-size: 14px;
            color: #ffffff;
        }
    }

    .B-body{
        padding: 0px;
        color: white;
        font-size: 13px;
        .nav-tabs{
            .nav-link{
                border-radius: 0px;
                border: solid 1px #1F1F1F;
                border-width: 0px 0px 1px;
                color: white !important;
                font-size: 14px;
                &.active{
                    background: transparent;
                    border-bottom:solid 2px #999999;
                    color: white !important;
                }
            }
        }

        .tab-content{
            // padding: 15px;
            h3{
                font-size: 16px;
            }
            .heading{
                background: #232323;
                padding: 5px;
            }
            .details{
                border-bottom: solid 1px #232323;
                padding: 5px;
                &:last-child{
                    border-bottom: 0px;
                }
            }
        }
    }
}

.full{
    background:#FFE6E4;
    display: block;
    text-align: center;
    padding: 3px 10px;
    border-radius: 25px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight:700;
}

.join{
    background:#3E4095;
    display: block;
    text-align: center;
    padding: 3px 10px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 11px;
    color: white;
    font-weight:700;
}
.carousel-control-prev{
    width: auto;
    opacity: 1;
    left: -27px;
    .carousel-control-prev-icon{
        width: 1.5rem;
        height: 1.5rem;
        background-color: #ffb80c;
        background-size: 65%;
        border-radius: 4px;
       
    }
}

.carousel-indicators{
    display: none;
}

.carousel-control-next{
    width: auto;
    opacity: 1;
    right: -27px;
    .carousel-control-next-icon{
        width: 1.5rem;
        height: 1.5rem;
        background-color: #ffb80c;
        background-size: 65%;
        border-radius: 4px;
       
    }
}


.marque{
    background: #FF6600;
    padding: 10px 15px 5px;
    border-radius: 5px;
    white-space:nowrap;
    span{
        margin-right: 10px;
        display: inline-block;
        color: #030A1F;
    }
}  


.error{
    background: #f8d7da;
    display: block;
    border: solid 1px #f5c6cb;
    padding: 8px 15px;
    color: #721c24;
    border-radius: 5px;
}


.success{
    background: #d4edda;
    display: block;
    border: solid 1px #c3e6cb;
    padding: 8px 15px;
    color: #155724;
    border-radius: 5px; 
}


.blockTitle{
    font-size: .9rem;
    padding: 10px 15px;
    margin: 0px;
    font-weight: 700;
    text-transform: uppercase;
}
.cmenu{
    margin: 0px;
    padding:0px 5px;
    list-style: none;
    width: 100%;
    background: transparent;
    li{
        text-align: left;
        padding: 0px 10px;
        a{
            font-size: 14px;
            text-align: left;
            padding: 9px 0;
            color: black;
            display: block;
            text-transform: none;
            text-decoration: none;
            &:hover{
                background: transparent;
                color: #2DBCF1;
            }
            &.active{
                color: #2DBCF1;
                background: transparent;
                border-right: solid 2px #2DBCF1;
            }
        }
        ul{
            margin: 0px;
            padding: 0px;
            list-style: none;
            width: 100%; 
            background: #F9F9F9;
            border-radius: 5px;
            a{
                font-size: 12px;
                &.active{
                    color: #3E4095;
                    border: 0;
                }
            }
        }
    }
}

.cBlock{
    // border: solid 1px rgba(255, 240, 218, 0.2);
    background: #303030;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: center;
    img{
        width: auto;
        border-radius: 3px;
        height: 150px;
        max-width: 100%;
        object-fit: contain;
    }

    h2{
        font-size: 13px;
        text-align: center;
        font-weight: 500;
        // color: rgba($color: #E3F8FF, $alpha: .8);
        // margin: 15px 0px 10px;
        a{
            color:white;
            text-decoration: none;
        }
    }
}


.winPrid{
    background: #F9F9F9;
    padding: 10px;
    border: solid 1px rgba(0,0,0,0.07);
    width: 70%;
    margin: 20px auto 0px;
    border-radius: 5px;
    label{
        font-size: 12px;
        margin-bottom: 5px;
    }

    .form-control{
        border: 0px;
        font-size: 13px;
    }
}

.BetRow{
    text-align: center;
    span{
        display: inline-block;
        width: 32%;
        background: #000;
        margin: 0px 2px;
        color: white;
        padding: 5px;
        border-radius: 3px;
        img{
            width: 15px;
            display: inline-block;
            margin-right: 5px;
        }
        &:first-child{
            background: #C20B4E;
        }

        &:nth-child(2){
            background: #3656FF;
        }
    }
}

.BetAMount{
    label{
        font-size: 13px;
        margin-bottom: 3px;
    }

    .form-control{
        border-color: rgba(0,0,0,0.07);
        font-size: 13px;
        background-color: #F9F9F9;
    }
}

.colorsWins{
    font-size: 12px;
    .row{
        border-bottom: solid 1px rgba(0,0,0,0.05);
        padding: 10px 0px;
    }
    .dots{
        width: 8px;
        height: 8px;
        background: #E9055A;
        border-radius: 50%;
        margin-right: 5px;
    }
    .rightTxt{
        color: #3656FF;
        img{
            width: 10px;
            margin-right: 1px;
        }
    }
}


.gTLZXx{
    width: 25px !important;
    transform: rotate(135deg);
    left: calc(50% - 13px);
    bottom: 0px;
    top: auto!important;
}

.btmBox{
    background: rgb(48, 48, 48);
    border-radius: 5px;
    color: #fff;
    margin-top: 20px;
}

.wheel{
    max-width:445px;
    margin: 0px auto;
    button{
        width: 140px;
        height: 40px;
        font-weight: bold;
        margin-top: 20px;
        border-radius: 25px;
        border: 0px;
        background: #5AC8F4;
        color: white;
    }
}

.lb-box{
    background: white;
    color: black;
    .nav-tabs{
        button{
            color: black;
        }
    }
}

.Ccats{
    background: rgba($color: #ffb80c, $alpha: .2);
    border-radius: 5px;
   ul{
    padding: 0px;
    margin: 0px;
    list-style: none;
    li{
        display: inline-block;
        margin-right: 1px;
        a{
            padding: 10px 15px;
            display: block;
            color: #fff;
            border-radius: 5px;
            text-decoration: none;
            &:hover{
                background: rgba($color: #ffb80c, $alpha: 1);  
            }
            &.active{
                background: rgba($color: #ffb80c, $alpha: 1);  
            }
        }
    }
   }
}




.casinoTabs{
    .nav-tabs{
        background: rgba($color: #ffb80c, $alpha: .2);
        border-radius: 5px;
        white-space: nowrap;
        padding: 3px;
        overflow-x: auto;
        display: block;
        .nav-item{
            display: inline-block;
        }
        .nav-link{
            border-color: transparent;
           
        }
    }
    
}